import { configureStore } from "@reduxjs/toolkit";

import { openedFormReducer } from "./formSlice";
import { searchDataReducer } from "./searchDataSlice";
import { searchParamsReducer } from "./searchParamsSlice";
import { profileDataReducer } from "./profileDataSlice";
import api from "./api";


export const store = configureStore({
  reducer: {
    openedForm: openedFormReducer,
    searchData: searchDataReducer,
    searchParams: searchParamsReducer,
    profileData: profileDataReducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware])
});

export type RootState = ReturnType<typeof store.getState>;
