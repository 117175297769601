import { WidgetProps } from "@rjsf/utils";
import cx from 'classnames'

import { TextInput } from "./TextInput";
import { ClearButton } from "../button/ClearButton";
import { makeStyles } from "@mui/styles";
import { useMemo } from "react";
import { isIsoDate } from "../table/Table/helpers";

const exceptions = ['mimetype', 'filename']

export const TextGroup = (props: WidgetProps) => {
    const isDisabled = useMemo(() => {
        const isException = !!props.id.split('_').find(item => {

            return exceptions.find(exc => item === exc)
        })
        return isException || isIsoDate(props.value)
    },[props.id, props.value])


    const styles = useStyles();
    const classes = cx(styles.wrapper, isDisabled ? styles.disabled : null)

    const handleChange = (val: string) => {
        if(props.schema.type === 'integer' || props.schema.type === 'number'){
            if(!!Number(val)){
                props.onChange(val)
            }
            return
        }

        props.onChange(val)
    }

    return (
      <div className={classes}>
          <label className={styles.label} htmlFor={props.id}>{props.label}</label>

          <TextInput
              {...props}
              id={props.id}
              value={props.value || ''}
              placeholder={props.placeholder}
              handleOnChange={handleChange}
              onBlur={() => props.onBlur(props.id, props.value)}
              onFocus={() => props.onFocus(props.id, props.value)}
          />

          {!!props.value && !isDisabled && (
              <ClearButton onClear={() => props.onChange('')} />
          )}
      </div>
    )
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '11rem 1fr',
        columnGap: '1rem',
        alignItems: 'center',
        '& + .MuiList-root.MuiList-dense': {
            display: 'none'//temporary solution, waits when we will have errors from server
        }
    },
    disabled: {
        opacity: 0.6,
        pointerEvents: 'none'
    },
    label: {
        textTransform: 'capitalize'
    }
}));
