import { Link as NavLink, useParams, useNavigate } from "react-router-dom";
import { Box, Button, Link as MuiLink, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { To } from "react-router"
import { useMemo } from "react";
import { HalObject, Link } from "@contentgrid/hal";

import { PageBlockWrapper } from "../../ui/PageBlockWrapper";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { isIsoDate } from "../../ui/table/Table/helpers";
import { DeleteEntityInstance } from "./../DeleteEntityInstance";
import { FileSelect } from "../../ui/FileSelect";
import { EntityInstance as EntityInstanceType } from "../../repository/models";
import { convertFromIso, getContentLinks, getPropertyList } from "../../ui/form/helpers";
import api from "../../store/api";
import { formatFileSize } from "./../helpers";
import cgRelations from "../../repository/relations";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { selectSearchParams } from "../../store/searchParamsSlice";
import { Relation } from "./Relation";

export const EntityInstance = () => {
    const { slug, instance } = useParams()
    const { data, isLoading, error } = api.useGetEntityInstanceQuery(`${slug}/${instance}`)
    const navigate = useNavigate()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const storedParams = useTypedSelector(selectSearchParams)

    const url = useMemo(() => {
        if(!storedParams){
            return null
        }

        const params = new URLSearchParams(storedParams)
        const searchParams = params.toString()

        return `/${slug}/search?${searchParams}`
    },[storedParams, slug])

    return (
        <PageBlockWrapper title='Details'>
            <Box width={800} display='flex' alignItems='center' justifyContent='space-between'>
                {!!url ? (
                    <BackLink to={url}>
                        Back to list
                    </BackLink>
                ) : null}

                <Box display='flex' gap={2}>
                    {!isLoading && !error ? (
                        <>
                            <Button color='primary' variant='outlined' onClick={() => navigate(`/${slug}/edit/${instance}`)}>Edit</Button>
                            <DeleteEntityInstance id={instance!} />
                        </>
                    ) : null}
                </Box>
            </Box>

            
            {isLoading || !!error ? (
                <Box marginTop={4}>
                    <RequestStateHandler isLoading={isLoading} error={error} />
                </Box>
            ) : !!data ? <Content data={data} /> : null}
        </PageBlockWrapper>
    )
}

type BackLinkProps = {
    to: To,
    children: React.ReactNode,
}

export default function BackLink({ to, children }: BackLinkProps) {
    const classes = useStyles();
    return (
        <MuiLink className={classes.link} component={NavLink} to={to}>
            <ArrowBack fontSize='inherit' />{children}
        </MuiLink>
    )
}

interface ContentProps {
    data: EntityInstanceType
}

const Content = ({data}: ContentProps) => {
    const classes = useStyles();

    const { links, details, content, relations } = useMemo(() => {
        if(!!data){
            const hal = new HalObject(data)

            const links = getContentLinks(new HalObject(data))

            const linkNames = links.map(item => item.name)

            const content = (Object.entries(data)).filter(([name]) => linkNames.includes(name))

            const details = getPropertyList(data, linkNames)

            const relations = hal?.links.findLinks(cgRelations.relation)

            return {
                links,
                details,
                content,
                relations
            }
        }
         
        return { links: null, details: null, content: null, relations: null }
    },[data])

    return (
        <Box display='flex' flexWrap='wrap'>
            <Box marginTop={4} width={800} marginBottom={4} marginRight={4}>
                <Table size='small'>
                    <TableBody>
                        {(details ?? []).map((detail) => <DetailsRow key={detail[0]} value={detail} /> )}

                        {(content ?? []).map((contentItem) => <ContentRow key={contentItem[0]} value={contentItem} /> )}
                    </TableBody>
                </Table>

                {!!relations?.length ? (
                    <Box marginTop={2}>
                        <Typography gutterBottom>Relations</Typography>

                        {relations.map((link: Link) => <Relation key={link.href} link={link} />)}
                    </Box>
                ) : null}
            </Box>

            {!!links?.length ? (
                <div className={classes.wrapper}>
                    <FileSelect links={links} />
                </div>
            ) : <></>}
        </Box>
    )
}

const CellValue = ({value}: {value: null | string | string[]}) => {
    if(value === null){
        return <></>
    }

    if(Array.isArray(value)){
        const content = value.map(item => isIsoDate(item) ? convertFromIso(item) : item)
        return (
            <Typography color='textPrimary' component='span'>
                <>{content.join(', ')}</>
            </Typography>
        )
    }

    const content = isIsoDate(value) ? convertFromIso(value) : value
    return (
        <Typography color='textPrimary' component='span'>
            <>{[content]}</>
        </Typography>
    )
}

const DetailsRow = ({value}: {value: [string, string | string[]]}) => {
    return (
        <TableRow>
            <TableCell size='small' sx={{ maxWidth: '250px', verticalAlign: 'top' }}>
                <Typography
                    variant='body2'
                    color='textSecondary'
                    component='span'
                    sx={{ paddingTop: '3px', display: 'inline-block' }}
                >{value[0]}</Typography>
            </TableCell>

            <TableCell sx={{ verticalAlign: 'top' }}>
                <CellValue value={value[1]} />
            </TableCell>
        </TableRow>
    )
}

const ContentRow = ({value}: {value: [string, string | string[]]}) => {
    const contentValue = !!value[1] ? Object.entries(value[1]) : null

    return (
        <TableRow>
            <TableCell size='small' sx={{ maxWidth: '250px', verticalAlign: 'top' }}>
                <Typography
                    variant='body2'
                    color='textSecondary'
                    component='span'
                    sx={{ paddingTop: '3px', display: 'inline-block' }}
                >{value[0]}</Typography>
            </TableCell>

            <TableCell padding='none' size='small' sx={{ verticalAlign: 'top' }}>
                <Table>
                    <TableBody>
                        {(contentValue ?? []).map(([valueKey, valueBody]) => {
                            const value = valueKey === 'length' ? formatFileSize(parseInt(valueBody)) : !!valueBody ? valueBody : '-'
                            return (
                                <TableRow key={valueKey}>
                                    <TableCell sx={{ borderBottom: 'none'}} size='small'>{valueKey}: {value}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>
    )
}

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    wrapper: {
        width: '800px',
        marginLeft: theme.spacing(4),
        paddingTop: theme.spacing(2)
    }
}));
