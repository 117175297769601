import fetcher from '../../repository'

export const upload = async (url: string, file: File, onProgress: (percentage: number, status?: { loaded?: boolean, error?: boolean }) => void) => {
    return new Promise((resolve) => {
        performUpload(url, file, onProgress, resolve)
    })
}

const performUpload = (
    url: string,
    file: File,
    onProgress: (percentage: number, status?: { loaded?: boolean, error?: boolean }) => void,
    resolve: (val: any) => void
) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    xhr.onload = function() {
        onProgress(100, { loaded: true })
        resolve(null)
    }

    xhr.onerror = function() {
        onProgress(100, { error: true })
        resolve(null)
    }

    xhr.upload.onprogress = e => {
        if (e.lengthComputable) {
            const percentComplete = (e.loaded / e.total) * 100;
            onProgress(percentComplete)
        }
    }

    var formData = new FormData();
    formData.append('file', file, file.name);
    const token = fetcher.getAccessToken()
    xhr.setRequestHeader("Authorization", "Bearer "+token)
    
    xhr.send(formData);
}
