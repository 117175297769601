import { styled, List, ListItem } from "@mui/material"
import { NavLink, useParams } from "react-router-dom";
import cx from 'classnames'
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { toggleForm } from "../store/formSlice";

interface NavMenuProps {
    options: {label: string, value: string}[],
}

export const NavMenu = ({ options }: NavMenuProps) => {
    const { slug: selected } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!selected){
            dispatch(toggleForm(true))
            return
        }
        dispatch(toggleForm(false))
    },[selected, dispatch])

    return (
        <StyledWrapper>
            {options.map(option => {
                const classes = cx({ 'is-selected': option.value === selected })

                return (
                    <StyledItem key={option.value}>
                        <StyledNavLink className={classes} to={option.value}>{option.label}</StyledNavLink>
                    </StyledItem>
                )
            })}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(List)({
    maxWidth: '100%'
})

const StyledItem = styled(ListItem)(({theme}) => ({
    padding: 0,
    '&:not(:last-child)': {
        marginBottom: theme.spacing(2)
    }
}))

const StyledNavLink = styled(NavLink)(({theme}) => ({
    textDecoration: 'none',
    width: '100%',
    padding: '0.5rem 1rem',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid '+theme.palette.grey[100],
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'capitalize',
    '&:focus-visible': {
        outline: 0,
        backgroundColor: theme.palette.grey[300],
        border: '1px solid '+theme.palette.grey[300],
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
    },
    '&.is-selected': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    },
}))
