import { useNavigate, useParams } from "react-router-dom";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IChangeEvent } from "@rjsf/core";
import { useState } from "react";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { selectSearchData } from "../store/searchDataSlice";
import { RootState } from "../store/store";
import { JsonForm } from "../ui/form/JsonForm";
import api from "../store/api";
import { ServerErrorMessage } from "../ui/ServerErrorMessage";

export const CreateEntityInstance = () => {
    const { slug } = useParams()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const entity = useTypedSelector(selectSearchData)
    const [createInstance, {isLoading, error}] = api.useCreateEntityInstanceMutation()

    const navigate = useNavigate()
    const [values, setValues] = useState<IChangeEvent>()

    const handleChange = (data: IChangeEvent) => {
        setValues(data)
    }

    const onSubmit= () => {
        const isValues = !!Object.values(values?.formData).filter(item => !!item).length
        if(isValues && !!entity){
            createInstance({entity: entity, value: values?.formData}).then(response => {
                if('data' in response){
                    navigate(`../${slug}/details/${response.data.id}`)
                }
            })
        }
    }

    if(entity === null){
        return <></>
    }

    return (
        <PageBlockWrapper title='Create'>
            {!!error ? <ServerErrorMessage error={error} /> : null}
            {!!entity ? (
                <JsonForm
                    properties={entity?._templates?.["create-form"].properties}
                    formData={values?.formData}
                    onChange={handleChange}
                >
                    <Box display='flex' justifyContent='flex-end'>
                        <Box marginRight={2}><Button color='secondary' onClick={() => navigate(`/${slug}/search`)}>Cancel</Button></Box>
                        <LoadingButton loading={isLoading} id='submit-button' variant='contained' onClick={onSubmit}>Create</LoadingButton>
                    </Box>
                </JsonForm>
            ) : null}
        </PageBlockWrapper>
    )
}
