
import { Box, CircularProgress } from '@mui/material'

export const ProgressIndicator = ({loadingSize}: {loadingSize?: number}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} marginBottom={2}>
      <CircularProgress size={loadingSize} />
    </Box>
  )
}
