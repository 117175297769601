import { Alert, Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { HalObject } from "@contentgrid/hal";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { NavMenu } from '../ui/NavMenu';
import { Signature } from '../ui/Signature';
import { ProgressIndicator } from '../ui/ProgressIndicator';
import api from "../store/api";
import cgRelations from "../repository/relations";
import { ServerErrorMessage } from "../ui/ServerErrorMessage";

export const Sidebar = () => {
    const { data, isLoading, error } = api.useGetProfilesQuery(null);
    const classes = useStyles();

    return (
        <PageBlockWrapper isSidebar title='Entities'>
            {isLoading ? (
                <Box marginTop={4}><ProgressIndicator /></Box>
            ) : !!error && "status" in error && error.status === 403 ? (
                <Alert severity='error'>No Permissions!</Alert>
            ) : !!error ? (
                    <ServerErrorMessage error={error} classes={classes.error} />
            ) : (
                <NavMenu options={createOptions(new HalObject(data!))} />
            )}

            <Signature />
        </PageBlockWrapper>
    )
}

function createOptions(hal: HalObject<unknown>): Record<"label"|"value", string>[] {
    const entities = hal.links.findLinks(cgRelations.entity);

    return entities.map(link => ({
        label: link.name!,
        value: link.name!
    }))

}

const useStyles = makeStyles(() =>
    createStyles({
        error: {
            maxWidth: '100%',
            '& .MuiAlert-message': {
                wordWrap: 'break-word'
            }
        }
    })
)
