import { User as OidcUser } from "oidc-client-ts"

import IDataFetcher from "./IDataFetcher";
import { HttpJsonError } from "./HttpError";
import getConfig, { AppConfig } from "../config";

export default class DataFetcher implements IDataFetcher {
    private get config(): AppConfig {
        return getConfig()!;
    }

    public getAccessToken(): string {
        return this.getToken() as string
    }

    private getToken() : String | null {
        const oidcConfig = this.config.oidc;
        var oidcKey = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`;
        const oidcStorage = sessionStorage.getItem(oidcKey);
        if (!oidcStorage) {
            return null;
        }

        var oidcUser =  OidcUser.fromStorageString(oidcStorage);
        return oidcUser?.access_token;
    }

    private async fetch(url: string, init?: (RequestInit | undefined)): Promise<Response> {
        const token = this.getToken();
        let method = init?.method ?? "GET";
        if (!token) {
            throw console.log('Error')
        }

        init = {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: `Bearer ${token}`,
            }
        }

        const response = await fetch(url, init);
        if(!response.ok) {
            throw await HttpJsonError.tryFromResponse(method, url, response);
        }

        return response;
    }
}
