import { Box, Typography } from "@mui/material";
import { HalObject, HalSlice } from "@contentgrid/hal";
import { MUIDataTableMeta } from "mui-datatables";
import { useParams } from "react-router-dom";
import { useState } from "react";

import api from "../../store/api";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { ActionsCell } from "./ActionsCell";
import { PageBlockWrapper } from "../../ui/PageBlockWrapper";
import BackLink from "./EntityInstance";
import { ResultsTable } from "../ResultsTable";
import { TableBody } from "../../ui/table/Table/components/TableBody";
import { getColumns } from "../../ui/table/Table/helpers";
import { CustomCell } from "../../ui/table/Table/components/CustomCell";
import { createActionsColumn } from "../helpers";
import cgRelations from "../../repository/relations";

export const AllRelationsPage = () => {
    const {slug, instance, relation} = useParams()

    return (
        <PageBlockWrapper title={<Typography variant='h6' component='span' textTransform='capitalize'>{slug}: {relation}</Typography>}>
            <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom={2}>
                <BackLink to={`/${slug}/details/${instance}`}>
                    Back to details
                </BackLink>
            </Box>

            <Content />
        </PageBlockWrapper>
    )
}

const Content = () => {
    const {slug, instance, relation} = useParams()
    const [path, setPath] = useState(`${slug}/${instance}/${relation}`)
    const {data, isLoading, error} = api.useGetEntityInstanceRelationQuery(path)
    const { data: entityData } = api.useGetEntityInstanceQuery(`${slug}/${instance}`)

    const link = getLink(entityData, relation!)

    if(!data && (isLoading || !!error)){
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if(!!data){
        const halData = new HalSlice(data)

        const columns = getColumns(
            halData,
            createActionsColumn((
                _value: unknown,
                tableMeta: MUIDataTableMeta) => (
                    <ActionsCell
                        tableMeta={tableMeta}
                        halSlice={halData}
                        link={link!}
                    />
                )
            ),
            (value: unknown, tableMeta: MUIDataTableMeta) => <CustomCell value={value} tableMeta={tableMeta} />
        )

        return (
            <ResultsTable data={halData} onNext={() => setPath(halData.next?.href!)} onPrev={() => setPath(halData.previous?.href!)}>
                <TableBody
                    instances={halData?.items}
                    columns={columns}
                    elements={(halData?.data as any)?.page?.totalElements} />
            </ResultsTable>
        )
    }

    return <></>
}

const getLink = (value: any, relation: string) => {
    if(!!value){
        const links = new HalObject(value).links.findLinks(cgRelations.relation)
        const link = links.find(link => link.name === relation)

        return !!link ? link : null
    }
    return null
}
