import { Alert } from '@mui/material'
import { styled } from '@mui/styles'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

interface ServerErrorMessageProps {
  error?: FetchBaseQueryError | SerializedError,
  classes?: string,
}

export const ServerErrorMessage = ({ error, classes }: ServerErrorMessageProps) => {
  if(!error){
    return null
  }

  const message = getErrorMessage(error)

  return (
    <StyledAlert className={classes} severity='error'>Server error: <>{message}</></StyledAlert>
  )
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

const getErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  if("message" in error){
    return error.message
  }
  if("data" in error && typeof error.data === 'object' && !!error.data && "message" in error.data){
    return error.data.message
  }
  return JSON.stringify(error)
}
