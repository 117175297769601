import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from "./store";
export const selectProfileData = (state: RootState) => state.profileData;

type ProfileDataStateType = {[k: string]: string} | null; 
const initialState = null as ProfileDataStateType

export const profileDataSlice = createSlice ({
  name: "profileData",
  initialState,
  reducers: {
    updateProfileData: (
        state: ProfileDataStateType,
        { payload }: PayloadAction<ProfileDataStateType>
      ) => (
        state = payload
      )
  }
})

export const { updateProfileData } = profileDataSlice.actions;

export const profileDataReducer = profileDataSlice.reducer;
