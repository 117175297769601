import { Link as NavLink } from "react-router-dom";
import { Box, Typography, Tooltip } from "@mui/material";
import { DeleteForever, Toc } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import { HalSlice, Link } from "@contentgrid/hal";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { getDetailsUrlPath } from "../helpers";
import { selectProfileData } from "../../store/profileDataSlice";
import { RootState } from "../../store/store";
import { UnlinkRelationDialog } from "./UnlinkRelationDialog";

export const RelationTitle = ({data, link}: {data: any, link: Link}) => {
    const classes = useStyles()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const entityList = useTypedSelector(selectProfileData)
    const [opened, setOpened] = useState(false)

    const isMultipleRelation = !!data && '_embedded' in data

    const url = useMemo(() => {
        if(isMultipleRelation || !data){
            return null
        }

        const halSlice = new HalSlice(data)

        const path = getDetailsUrlPath(
            data.id,
            halSlice,
            entityList!
        )

        return path
    },[isMultipleRelation, data, entityList])

    return (
        <Box display='flex' justifyContent='space-between' width='100%'>
            <Typography>{link.name}</Typography>

            {isMultipleRelation ? (
                <></>
            ) : !!data ? (
                <>
                    <Box display='flex'>
                        <Tooltip arrow placement='top' title='Details'>
                            <NavLink className={classes.link} to={url!}><Toc color='inherit' /></NavLink>
                        </Tooltip>

                        <Tooltip arrow placement='top' title='Unlink'>
                            <span className={classes.link} onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setOpened(true)
                            }}>
                                <DeleteForever color='inherit' fontSize='small' />
                            </span>
                        </Tooltip>
                    </Box>

                    <UnlinkRelationDialog
                        link={link}
                        opened={opened}
                        onClose={() => setOpened(false)}
                    />
                </>
            ) : <></>}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    link: {
        width: 'auto',
        textAlign: 'center',
        display: 'inline-flex',
        color: theme.palette.grey[600],
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}));
