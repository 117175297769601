import { Outlet, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { Alert, Box } from "@mui/material";

import { Sidebar } from "./Sidebar";
import { Scroll } from "../ui/Scroll";
import { ProgressIndicator } from "../ui/ProgressIndicator";
import api from "../store/api";

export const Main = () => {
    const {slug} = useParams()
    const { isLoading, error } = api.useGetProfileEntityQuery(slug!, { skip: !slug, refetchOnMountOrArgChange: true })

    return (
        <StyledMainWrapper>
            <Sidebar />

            <Scroll>
                {isLoading ? ( 
                    <Box marginTop={2}><ProgressIndicator /></Box>
                ) : !!error ? (
                    <Box margin={2}><Alert severity='error'>Entity does not found!</Alert></Box>
                ) : <Outlet />}
            </Scroll>
        </StyledMainWrapper>
    )
}

const StyledMainWrapper = styled('div')(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '20rem 1fr',
    paddingTop: theme.spacing(10),
    height: '100%'
}))
