import { AppConfig } from "../config";
import { FormControl, InputLabel, Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

import { TextInput } from "../ui/form/TextInput"

interface ConfigurationProps {
    config: AppConfig | null;
    onChange(config: AppConfig): void;
}

export default function Configuration(props: ConfigurationProps) {
    const classes = useStyles();
    const defaultConfig = props.config ?? {
        apiBaseUrl: "",
        oidc: {
            authority: "",
            client_id: ""
        }
    }

    const baseUrlValid = normalizeUrl(defaultConfig.apiBaseUrl) !== null;

    return <>
        <FormControl error={!baseUrlValid} color={baseUrlValid ? undefined : 'error'}>
            <InputLabel className={classes.label} htmlFor="base-url-input">Base URL</InputLabel>
            <TextInput
                autoFocus
                id="base-url-input"
                aria-describedby="base-url-input-text"
                type="url"
                value={defaultConfig.apiBaseUrl}
                handleOnChange={(apiBaseUrl) => props.onChange({ ...defaultConfig, apiBaseUrl })}
            />
        </FormControl>

        <FormControl error={normalizeUrl(defaultConfig.oidc.authority) == null}>
            <InputLabel className={classes.label} htmlFor="authority-input">Issuer URI</InputLabel>
            <TextInput
                id="authority-input"
                aria-describedby="authority-input-text"
                type="url"
                value={defaultConfig.oidc.authority}
                handleOnChange={(authority) => props.onChange({ ...defaultConfig, oidc: { ...defaultConfig.oidc, authority } })}
            />
        </FormControl>

        <FormControl>
            <InputLabel className={classes.label} htmlFor="client-id-input">Client ID</InputLabel>
            <TextInput
                id="client-id-input"
                aria-describedby="client-id-input-text"
                value={defaultConfig.oidc.client_id}
                handleOnChange={(client_id) => props.onChange({ ...defaultConfig, oidc: { ...defaultConfig.oidc, client_id } })}
            />
        </FormControl>
    </>;
}

function normalizeUrl(string: string): string | null {
    try {
        const url = new URL(string);
        return url.href;
    } catch(e) {
        return null;
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            '&.MuiFormLabel-root': {
                textAlign: 'left !important'
            }
        }
    })
);
