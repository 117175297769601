import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Theme } from "@mui/material"
import { ExpandLess, ExpandMore, LogoutOutlined } from "@mui/icons-material"
import { ReactNode, useState } from "react"
import { createStyles, makeStyles } from "@mui/styles"

interface HeaderMenuProps {
    title: ReactNode
    onClick: () => void
}

export const HeaderMenu = ({ title, onClick }: HeaderMenuProps) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((active) => !active);
    };

    const classes = useStyles()

    return (
        <List className={classes.list} aria-labelledby="nested-list-profile">
            <ListItemButton className={classes.button} onClick={handleClick}>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit >
                <List className={classes.nestedList} disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={onClick}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Log out" />
                    </ListItemButton>
                </List>
            </Collapse>
        </List>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            '&.MuiList-root': {
                width: 'auto',
                backgroundColor: 'transparent',
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                paddingTop: theme.spacing(2)
            }
        },
        button: {
            borderRadius: theme.shape.borderRadius
        },
        nestedList: {
            width: 'auto',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.shadows[4]
        }
    })
);
