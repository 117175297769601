import { Box, Theme } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";
import { createStyles, makeStyles } from "@mui/styles";

import { TextInput } from "./TextInput";

export const UriGroup = (props: WidgetProps) => {
    const styles = useStyles()

    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: '11rem 1fr', columnGap: '1rem', alignItems: 'center' }}>
          <label htmlFor={props.id}>{props.label}</label>

          <TextInput
              {...props}
              className={styles.disabled}
              disabled
              onBlur={() => {}}
              onFocus={() => {}}
          />
      </Box>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        disabled: {
            pointerEvents: 'none',
            opacity: 0.5
        }
    })
);
