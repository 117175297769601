import { Box, Button, ListItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WidgetProps } from "@rjsf/utils";

export const BooleanGroup = ({ id, label, value, onChange }: WidgetProps) => {
    const classes = useStyles()

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '11rem min-content', columnGap: '1rem', alignItems: 'center' }}>
            <label htmlFor={id}>{label}</label>

            <Select
                className={classes.select}
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
            >
                <ListItem component={Button} className={classes.option} value=''><i>Any</i></ListItem>
                <ListItem component={Button} className={classes.option} value='true'>True</ListItem>
                <ListItem component={Button} className={classes.option} value='false'>False</ListItem>
            </Select>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    select: {
        width: theme.spacing(12),
        height: theme.spacing(5)
    },
    option: {
        height: theme.spacing(5),
        cursor: 'pointer',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize
    }
}));
