import { Alert } from "@mui/material";
import { HalSlice } from "@contentgrid/hal";
import { useSearchParams } from "react-router-dom";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { resolveTemplateRequired } from "@contentgrid/hal-forms";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";

import { ProgressIndicator } from "../ui/ProgressIndicator";
import { RootState } from "../store/store";
import { selectSearchData } from "../store/searchDataSlice";
import api from "../store/api";
import { getQueriesWithValue } from "../ui/form/helpers";
import { ProfileEntity } from "../repository/models";
import { ServerErrorMessage } from "../ui/ServerErrorMessage";
import { ResultsTable } from "./ResultsTable";
import { Table } from "../ui/table/Table/Table";

export const ResultsPage = () => {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const entity = useTypedSelector(selectSearchData)

    if (!entity) {
       return null
    }

    return (
        <ResultsPageContent entity={entity} />
    )
}

const ResultsPageContent = ({entity}: {entity: ProfileEntity}) => {
    const [searchParams] = useSearchParams();
    const [path, setPath] = useState(resolveTemplateRequired(entity, "search").request.url)
    const { data, isLoading, error } = api.useSearchCollectionQuery(entity ? {
        collectionUrl: path,
        parameters: getQueriesWithValue(searchParams.entries())
    }: skipToken);

    if (isLoading) {
       return <ProgressIndicator />
    }

    if (error) {
        if("status" in error && error.status === 403){
            return <Alert severity='error'>No Permissions!</Alert>
        }
        return <ServerErrorMessage error={error} />
    }

    const object = new HalSlice(data);

    return (
        <ResultsTable data={object} onNext={() => setPath(object.next?.href!)} onPrev={() => setPath(object.previous?.href!)}>
            <Table halSlice={object} data={object?.items} elements={(object?.data as any)?.page?.totalElements} />
        </ResultsTable>
    )
}
