import { MUIDataTableOptions } from "mui-datatables";

export const tableOptions: MUIDataTableOptions = {
  sort: false,
  pagination: false,
  filter: false,
  selectableRows: undefined,
  rowHover: true,
  rowsExpanded: [0, 1],
  filterType: 'dropdown',
  resizableColumns: false,
  textLabels: {
      body: {
          noMatch: 'Sorry, no results'
      }
  }
}

export const links = '_links'
export const originallyHiddenLabels = ['id']
