import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "@contentgrid/hal";
import { useMemo } from "react";

import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { EditContent } from "./EditContent";
import api from "../../store/api";
import { UploadFileStatusType } from "../../repository/models";

interface EditContentFieldProps { 
    link: Link,
    file: File | null | undefined,
    status: UploadFileStatusType | null,
    onUpload: (file: File | null, url: string) => void
}

export const EditContentField = (props: EditContentFieldProps) => {
    const classes = useStyles()
    const { data, isLoading } = api.useGetEntityInstanceContentQuery(props.link.href)

    const fileData = useMemo(() => {
        if(props.file !== null && props.file !== undefined){
            return {
                "content-length": props?.file.size+'',
                "content-type": props?.file.type,
                "content-name": props?.file.name
            }
        }

        if(props.file === null){
            return null
        }

        return data

    },[data, props.file])

    return (
        <div className={classes.wrapper}>
            <Typography textTransform='capitalize'>{props.link.name}</Typography>

            <Box>
                {isLoading ? (
                    <RequestStateHandler loadingSize={16} isLoading={isLoading} />
                ) : (
                    <EditContent
                        value={props.link.href}
                        data={fileData || null}
                        status={props.status}
                        onUpload={props.onUpload}
                    />
                )}
            </Box>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '11rem 1fr',
        columnGap: '1rem',
        maxWidth: '40rem',
        marginTop: '-0.5rem',
        marginBottom: theme.spacing(4)
    }
}));
