import { AccordionDetails, Accordion, AccordionSummary } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Link } from "@contentgrid/hal";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import api from "../../store/api";
import { RelationTitle } from "./RelationTitle";
import { RelationDetails } from "./RelationDetails";

export const Relation = ({link}: {link: Link}) => {
    const classes = useStyles();
    const {data, isLoading, error} = api.useGetEntityInstanceRelationQuery(link.href)

    const [expanded, setExpanded]= useState(false)

    return (
        <Accordion
            className={classes.accordion}
            disableGutters
            elevation={0}
            square
            expanded={expanded}
            onChange={() => setExpanded(active => !active)}
        >
            <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize='small' />}
            >
                <RelationTitle
                    link={link}
                    data={data}
                />
            </AccordionSummary>
            <AccordionDetails>
                <RelationDetails link={link} data={data} error={error} isLoading={isLoading} />
            </AccordionDetails>
        </Accordion>
    )
}

const useStyles = makeStyles((theme) => ({
    accordion: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
            paddingLeft: theme.spacing(5)
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            left: theme.spacing(2)
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    }
}));
