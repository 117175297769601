import { ForwardedRef, forwardRef } from 'react';
import { styled } from '@mui/material';
import { HalObject } from '@contentgrid/hal';

import { FileSelect } from '../../../FileSelect';
import { getContentLinks } from '../../../form/helpers';

const headerHeight = 372

interface ViewerProps {
    instance: HalObject<unknown> | null,
    onClose: () => void,
}

export const Viewer = forwardRef(({ instance, onClose}: ViewerProps, ref: ForwardedRef<any>) => {
    return (
        <StyledRightColumn ref={ref}>
            <ViewerContent instance={instance} onClose={onClose} />
        </StyledRightColumn>
    )
})

export const ViewerContent = ({ instance, onClose}: ViewerProps) => {
    if(instance === null){
        return null
    }

    const links = getContentLinks(instance)

    return (
        <FileSelect links={links} onClose={onClose} />
    )
}

const StyledRightColumn = styled('div')({
    height: `calc(100vh - ${headerHeight}px)`,
    '& ::-webkit-scrollbar': {
        display: 'none'
    }
})
