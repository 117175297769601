import { Close, FilePresent, Image, PictureAsPdf } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface FileCardProps {
    name?: string | null,
    size: string | null,
    type: string | null,
    onClick: () => void
}

export const FileCard = ({name, size, type, onClick}: FileCardProps) => {
    const classes = useStyles()

    return (
        <div className={classes.fileWrapper}>
            <div className={classes.file}>
                <Icon type={type} />
                <IconButton onClick={onClick}><Close fontSize='small' /></IconButton>
            </div>

            <Box display='flex' flexDirection='column' marginLeft={2}>
                <Typography>Name: <b>{!!name ? name : 'Document'}</b></Typography>
                <Typography>Size: <b>{size} </b></Typography>
                <Typography>Type: <b>{type}</b></Typography>
            </Box>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    fileWrapper: {
        display: 'flex',
    },
    file: {
        backgroundColor: theme.palette.grey[50],
        border: '1px solid '+theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        width: theme.spacing(16),
        minWidth: theme.spacing(16),
        height: theme.spacing(16),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',
        overflow: 'hidden',
        '& button': {
            position: 'absolute',
            right: '0',
            top: '0'
        }
    }
}));

const Icon = ({type}: {type: string | null}) => {
    if(type === 'application/pdf'){
        return <PictureAsPdf fontSize='large' color='primary' />
    }
    if(type?.startsWith('image/')){
        return <Image fontSize='large' color='primary' />
    }
    return <FilePresent fontSize='large' color='primary' />
}
