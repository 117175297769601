import { MUIDataTableMeta } from 'mui-datatables'
import { Box, Tooltip, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { DeleteForever, Edit, Toc } from '@mui/icons-material'

import { DeleteEntityInstance } from '../../../../components/DeleteEntityInstance';

interface ActionsCellProps {
    tableMeta: MUIDataTableMeta
}

export const ActionsCell = ({tableMeta}: ActionsCellProps) => {
    const handleClick = (e: React.SyntheticEvent) => {
        e.stopPropagation()
    }

    return (
        <Box onClick={handleClick} display='grid' alignItems='center' gridTemplateColumns='repeat(3, 1fr)' columnGap={1}>
            <Tooltip arrow placement='top' title='Details'>
                <StyledLink to={`../details/${tableMeta?.rowData[0]}`}><Toc color='inherit' /></StyledLink>
            </Tooltip>
            <Tooltip arrow placement='top' title='Edit'>
                <StyledLink to={`../edit/${tableMeta?.rowData[0]}`}><Edit fontSize='small' color='inherit' /></StyledLink>
            </Tooltip>
            <DeleteEntityInstance id={tableMeta?.rowData[0]} icon={(
                <StyledIcon>
                    <DeleteForever color='inherit' fontSize='small' />
                </StyledIcon>
            )}/>
        </Box>
    );
}

const StyledLink = styled(NavLink)(({theme}) => ({
    textAlign: 'center',
    width: '100%',
    display: 'inline-flex',
    color: theme.palette.grey[400],
    '&:hover': {
        color: theme.palette.common.black,
    }
}))

const StyledIcon = styled('span')(({theme}) => ({
    color: theme.palette.grey[400],
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.common.black,
    }
}))
