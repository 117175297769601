import { Box } from "@mui/material";
import { HalSlice } from "@contentgrid/hal";
import { ReactNode } from "react";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { PageNavigation } from "../ui/Navigation";

export const ResultsTable = ({
    data,
    children,
    onNext,
    onPrev
}: {
    data: HalSlice<unknown>,
    children: ReactNode,
    onNext: () => void,
    onPrev: () => void
}) => {

    return (
        <Box marginTop={4}>
            <PageBlockWrapper
                withoutSpacings
                header={data?.next || data?.previous ? (
                    <Header
                        nextLink={data?.next?.href ?? ''}
                        prevLink={data?.previous?.href ?? ''}
                        onNext={onNext}
                        onPrev={onPrev}
                    />
                ) : null}
            >
                {children}
            </PageBlockWrapper>
        </Box>
    )
}

interface HeaderProps {
    prevLink?: string,
    nextLink?: string,
    onNext: () => void,
    onPrev: () => void
}

const Header = ({ nextLink, prevLink, onNext, onPrev }: HeaderProps) => {
    return (
        <PageNavigation nextLink={nextLink} prevLink={prevLink} onNext={onNext} onPrev={onPrev} />
    )
}
