import { Box } from "@mui/material";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";
import { IChangeEvent } from "@rjsf/core";
import { ReactNode } from "react";

import { TextGroup } from "./TextGroup";
import { DateGroup } from "./DateGroup";
import { FormTemplate } from "./FormTemplate";
import { getPropertyObject, transformErrors } from "./helpers";
import { UriGroup } from "./UriGroup";
import { BooleanGroup } from "./BooleanGroup";
import { HalFormsPropertyShape } from "@contentgrid/hal-forms/shape";

const widgets = {
    TextWidget: TextGroup,
    DateWidget: DateGroup,
    DateTimeWidget: DateGroup,
    CheckboxWidget: BooleanGroup,
    URLWidget: UriGroup
}

interface JsonFormProps {
    properties: readonly HalFormsPropertyShape[],
    formData?: {[k: string]: string | undefined},
    children: ReactNode,
    onChange: (value: IChangeEvent) => void,
    onSubmit?: () => void
}

export const JsonForm = ({
    formData,
    properties,
    children,
    onChange,
    onSubmit
}: JsonFormProps) => {
    const schema = getPropertyObject(properties)

    if(!schema){
        return null
    }

    return (
        <Box sx={{
            maxWidth: '40rem',
            marginBottom: 4
        }}>
            <Form
                liveValidate
                schema={schema}
                formData={formData}
                validator={validator}
                uiSchema={{ "ui:ObjectFieldTemplate": FormTemplate }}
                showErrorList={false}
                transformErrors={transformErrors}
                widgets={widgets}
                onChange={onChange}
                onSubmit={onSubmit}
            >
                {children}
            </Form>
        </Box>
    )
}
