export interface AppConfig {
    apiBaseUrl: string;
    oidc: {
        authority: string;
        client_id: string
    }
}

declare global {
    interface Window {
        contentGridConfig?: {
            v1?: AppConfig
        }
    }
}

export default function getConfig(): AppConfig | null {
    return getProvidedConfig() ?? getRuntimeConfig() ?? null;
}

function getProvidedConfig(): AppConfig | null {
    return window.contentGridConfig?.v1 ?? null;
}

const localStorageKey = "contentGridConfig:v1";

function getRuntimeConfig(): AppConfig | null {
    const config = localStorage.getItem(localStorageKey);
    if(config == null) {
        return null;
    }
    return JSON.parse(config) as AppConfig;
}

export function setRuntimeConfig(config: AppConfig) {
    if(isRuntimeConfigurable()) {
        localStorage.setItem(localStorageKey, JSON.stringify(config));
    }
}

export function clearRuntimeConfig() {
    if(isRuntimeConfigurable()) {
        localStorage.removeItem(localStorageKey);
    }
}

export function isRuntimeConfigurable() {
    return getProvidedConfig() == null;
}
