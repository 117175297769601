import { NavLink, Outlet, useParams } from "react-router-dom";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Add } from '@mui/icons-material'
import { Button } from "@mui/material";
import { useMemo } from "react";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { SearchForm } from "./SearchForm";
import { selectSearchData } from "../store/searchDataSlice";
import { RootState } from "../store/store";
import { ProfileEntity } from "../repository/models";
import { resolveTemplate } from "@contentgrid/hal-forms";

export const FormPage = () => {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const profileEntity = useTypedSelector(selectSearchData)

    if(!profileEntity){
        return null
    }

    return (
        <PageBlockWrapper title="Search" header={<CreateInstanceButton entity={profileEntity} />}>
            <SearchForm properties={profileEntity._templates?.search?.properties} />

            <Outlet />
        </PageBlockWrapper>
    )
}

const CreateInstanceButton = ({entity}: {entity: ProfileEntity}) => {
    const { slug } = useParams()
    const template = useMemo(() => {
        return resolveTemplate(entity, 'create-form')
    },[entity])

    if(!template){
       return null
    }

    return (
        <Button
            component={NavLink}
            to={`/${slug}/create`}
            color="primary"
            variant="outlined"
            startIcon={<Add />}
        >Create</Button>
    )
}
