import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { resolveTemplate } from "@contentgrid/hal-forms";
import { HalObject } from "@contentgrid/hal";

import getConfig from "../config";
import dataFetcher from "../repository";
import { updateSearchData } from "./searchDataSlice";
import { EntityInstance, ProfileEntity } from "../repository/models";
import cgRelations from "../repository/relations";
import { updateProfileData } from "./profileDataSlice";
import { updateSearchParams } from "./searchParamsSlice";
import { convertArrayToUriList, getFilenameFromContentDisposition } from "./helpers";

export default createApi({
    baseQuery: (...args) => {
        const config = getConfig();
        return fetchBaseQuery({
            baseUrl: config?.apiBaseUrl,
            prepareHeaders(headers) {
                headers.append("Authorization", "Bearer "+dataFetcher.getAccessToken())
                headers.set('Accept', "application/prs.hal-forms+json, application/hal+json, application/json")
                
                return headers;
            },
        })(...args);
    },
    tagTypes: ['Instances', 'Instance', 'Relations'],
    endpoints: builder => ({
        getRoot: builder.query({
            query: () => '/',
        }),
        getProfiles: builder.query({
            query: () => '/profile',
            async onQueryStarted(_body, {dispatch, queryFulfilled}){
                queryFulfilled.then(result => {
                    const halObject = new HalObject(result.data)
                    const entities = halObject.links.findLinks(cgRelations.entity)
                    const obj: {[k: string]: string} = {}
                    entities.forEach(item => (obj[item.name!] = item.href))
                    dispatch(updateProfileData(obj))
                })
            } 
        }),
        getProfileEntity: builder.query({
            query: (slug: string) => `profile/${slug}`,
            async onQueryStarted(_body, {dispatch, queryFulfilled}){
                queryFulfilled.then(result => {
                    dispatch(updateSearchData(result.data))
                })
            } 
        }),
        searchCollection: builder.query({
            query: (search: CollectionSearch) => {
                const url = new URL(search.collectionUrl);
                Object.entries(search.parameters).forEach(([key, value]) => {
                    url.searchParams.append(key, value);
                })
                return url.href;
            },
            async onQueryStarted(body, {dispatch, queryFulfilled}){
                queryFulfilled.then(() => {
                    dispatch(updateSearchParams(body.parameters))
                })
            },
            providesTags: ['Instances']
        }),
        getEntityInstance: builder.query({
            query: (params: string) => {
                return params
            },
            providesTags: ['Instance']
        }),
        createEntityInstance: builder.mutation<EntityInstance, {entity: ProfileEntity, value: EntityInstance}>({
            query: ({entity, value}) => {
                const template = resolveTemplate(entity, 'create-form')?.request
                const url = template?.url
                const method = template?.method
        
                return {
                    headers: { "Content-Type": "application/json" },
                    url,
                    method: method,
                    body: JSON.stringify(value)
                }
            },
            invalidatesTags: [{ type: "Instances"}]
        }),
        editEntityInstance: builder.mutation<EntityInstance, {entity: EntityInstance, value: EntityInstance}>({
            query: ({entity, value}) => {
                const template = resolveTemplate(entity, 'default')?.request
                const url = template?.url
                const method = template?.method
        
                return {
                    headers: { "Content-Type": "application/json" },
                    url: url,
                    method: method,
                    body: JSON.stringify(value)
                }
            },
            invalidatesTags: [{ type: "Instances"}, { type: "Instance"}]
        }),
        deleteEntityInstance: builder.mutation<void, {entity: EntityInstance}>({
            query: ({entity}) => {
                const template = resolveTemplate(entity, 'delete')?.request
                const url = template?.url
                const method = template?.method
                return {
                    url:  url,
                    method: method
                }
            },
            invalidatesTags: [{ type: "Instances" }, { type: "Instance" }]
        }),
        getEntityInstanceContent: builder.query({
            query: (url: string) => {
                return {
                    url,
                    method: 'HEAD',
                }
            },
            transformResponse: async (_response, meta) => {
                if(!!meta?.response){
                    const length = meta?.response?.headers.get('content-length')
                    const type = meta?.response?.headers.get('content-type')

                    const disposition = meta?.response?.headers.get('content-disposition')
                    const name = !!disposition ? getFilenameFromContentDisposition(disposition) : null

                    return {
                        'content-length': length,
                        'content-type': type,
                        'content-name': name
                    }
                }
                
            },
            providesTags: ['Instance']
        }),
        deleteEntityInstanceContent: builder.mutation<void, {url: string}>({
            query: ({url}) => {
                return {
                    url,
                    method: 'DELETE'
                }
            },
            invalidatesTags: [{ type: "Instance"}]
        }),
        getEntityInstanceRelation: builder.query({
            query: (url: string) => {
                return {
                    url,
                }
            },
            providesTags: ['Relations']
        }),
        deleteEntityRelation: builder.mutation<void, {value: string}>({
            query: ({value}) => {
                return {
                    url:  value,
                    method: 'DELETE'
                }
            },
            invalidatesTags: [{ type: "Relations" }]
        }),
        addEntityRelation: builder.mutation<void, {value: string[], path: string}>({
            query: ({value, path}) => {
                return {
                    headers: { "Content-Type": "text/uri-list" },
                    url:  path,
                    method: 'PUT',
                    body: convertArrayToUriList(value)
                }
            },
            invalidatesTags: [{ type: "Relations" }]
        }),
        getRelatedEntity: builder.query({
            query: (slug: string) => `profile/${slug}`
        })
    })
});

interface CollectionSearch {
    collectionUrl: string;
    parameters: { [k: string]: string }
}
