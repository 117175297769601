import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IChangeEvent } from "@rjsf/core";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Box, Button, Collapse } from "@mui/material";

import { RootState } from "../store/store";
import { selectOpenedForm, toggleForm } from "../store/formSlice";
import { JsonForm } from "../ui/form/JsonForm";
import { CollapseButton } from "../ui/button/CollapseButton";
import { getQueriesWithValue } from "../ui/form/helpers";
import { HalFormsPropertyShape } from "@contentgrid/hal-forms/shape";

interface SearchFormProps {
    properties: readonly HalFormsPropertyShape[],
}

export const SearchForm = ({ properties }: SearchFormProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const opened = useTypedSelector(selectOpenedForm)
    const [values, setValues] = useState<IChangeEvent>()
    const location = useLocation()
    
    const handleChange = (data: IChangeEvent) => {
        setValues(data)
    }

    const onSubmit= () => {
        if(!!values?.formData){
            const isValues = !!Object.values(values?.formData).filter(item => item !== '').length

            if(isValues){
                const obj = getQueriesWithValue(Object.entries(values?.formData))

                const params = new URLSearchParams(obj)
                const searchParams = params.toString()
    
                navigate(`search?${searchParams}`)
                return
            }
        }
        navigate('search')
    }

    useEffect(() => {
        if(!!location.search){
            const params = new URLSearchParams(location.search)
            const obj = getQueriesWithValue([...params.entries()])
 
            setValues({ formData: obj } as IChangeEvent)
            return
        }
        setValues({} as IChangeEvent)
    },[location])

    const buttonText = useMemo(() => {
        if(!!values?.formData){
            return Object.values(values?.formData).filter(item => !!item).length > 0 ? 'Search' : 'Show all'
        }
        return 'Show all'
    },[values?.formData])

    return (
        <>
            <CollapseButton opened={opened} onClick={() => dispatch(toggleForm(!opened))} />

            <Collapse in={opened} appear={opened}>
                <JsonForm
                    properties={properties}
                    formData={values?.formData}
                    onSubmit={onSubmit}
                    onChange={handleChange}
                >
                    <Box display='flex' justifyContent='flex-end'>
                        <Button id='submit-button' variant='contained' onClick={onSubmit}>{buttonText}</Button>
                    </Box>
                </JsonForm>
            </Collapse>
        </>
    )
}
