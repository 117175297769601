import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import { CssBaseline, ThemeProvider } from '@mui/material';
import "simplebar-core/dist/simplebar.css";
import { Provider } from 'react-redux';

import { App } from './App';
import { theme } from './theme';
import { store } from './store/store';
import getConfig from './config';
import { UserManager } from 'oidc-client-ts';
import { Login } from './components/Login';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

type AuthProviderIfConfiguredProps = {
    children: ReactNode;
}

function AuthProviderIfConfigured(props: AuthProviderIfConfiguredProps) {
    const config = getConfig();
    if(config) {
        return <AuthProvider
            redirect_uri={window.location.origin}
            authority={config.oidc.authority}
            client_id={config.oidc.client_id}>
            {props.children}
        </AuthProvider>;
    }
    return <Login
        onClick={() => {
            const newConfig = getConfig()!;
            const userManager = new UserManager({
                redirect_uri: window.location.origin,
                authority: newConfig.oidc.authority,
                client_id: newConfig.oidc.client_id
            });

            userManager.signinRedirect();
        }}
    />
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Provider store={store}>
                <AuthProviderIfConfigured>
                    <App />
                </AuthProviderIfConfigured>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
