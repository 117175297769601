import { RefObject } from "react";
import { MUIDataTableColumnDef } from "mui-datatables";

import { convertFromIso } from "../../form/helpers";
import { originallyHiddenLabels } from "./variables";
import { HalObjectShape } from "@contentgrid/hal/shape";
import { HalSlice } from "@contentgrid/hal";
import { EntityInstance } from "../../../repository/models";

export const calculateDraggablePane = ():{left?: number, top?: number, right?: number, bottom?: number} | string => {
    let leftBoundary = 0 - (window.outerWidth/2) + 320;
    let rightBoundary = (window.outerWidth/2) - 320;
    return {left: leftBoundary, right: rightBoundary};
}

export const getTitles = (rows: HalObjectShape<unknown>[]) => {
    const updatedRows = rows.map(row => {
        const obj: {[k: string]: any} = {}
        Object.entries(row).forEach(entry => {
            if(typeof entry[1] !== 'object' || entry[1] === null){
                obj[entry[0]] = entry[1]
            }
        })
        return obj
    })

    return Object.keys(updatedRows[0])
}

export const updateRowDates = (rows: EntityInstance[]) => {
    return rows.map(row => {
        const entries = Object.entries(row)
        const newEntries = entries.map(([key, value]) => {
        if(typeof value === 'string'){
            
            if(isIsoDate(value.trim())){
            return { [key]: convertFromIso(value.trim()) }
            }

            const list = value.trim().split(' ')
            if(!!list.find(item => isIsoDate(item.trim()))){
            const newList = list.map(item => {
                if(isIsoDate(item.trim())){
                return convertFromIso(item.trim())
                }
                return item
            })
            return { [key]: newList.join(' ● ') }
            }

            return { [key]: value }
        }

        return { [key]: value }
        })

        const newValue: { [k: string]: any } = {}
        newEntries.forEach((item) => {
        const [key, value] = Object.entries(item)[0]
        newValue[key] = value
        })

        return newValue
    })
}

export const isIsoDate = (str: string) => {
    if(!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str) && !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str)){
        return false
    }
    
    const d = new Date(str)
    return d instanceof Date && !isNaN(Number(d)); // valid date 
}

export const getTableColumnSizes = (
    rightColumnRef: RefObject<HTMLDivElement>,
    leftColumnRef: RefObject<HTMLDivElement>,
    urlPresent: boolean,
    dividerLocation: number
    ) => {
    const leftWidth = ((dividerLocation/window.outerWidth) * 100) -1;
    const rightWidth = (((window.outerWidth - dividerLocation)/window.outerWidth) * 100) - 1;

    if(!!leftColumnRef?.current){
        leftColumnRef.current.style.width = urlPresent ? `${leftWidth}%` : '100%'
    }

    if(!!rightColumnRef?.current){
        const page = rightColumnRef.current?.querySelector<HTMLElement>('.rpv-core__inner-page')
        rightColumnRef.current.style.height = !!page ? `${page?.offsetHeight+64}px` : '100%'

        rightColumnRef.current.style.width = urlPresent ? `${rightWidth}%` : '0%'
    }
}

export const createColumn = (value: string, customRender: unknown, label?: string) => {
    return ({
        name: value,
        label: !!label ? label : '',
        options: {
            filter: true,
            sort: true,
            display: !originallyHiddenLabels.includes(value),
            customBodyRender: customRender
        }
    })
}

export const getColumns = (
    value: HalSlice<unknown>,
    actionsColumn: MUIDataTableColumnDef,
    customRender: unknown | null,
    actionColumnFirst?: boolean
): MUIDataTableColumnDef[] => {
    const data = (value.items ?? []).map(item => item.data)
    if(!!data?.[0]){
        const titles = getTitles(data)
        const columns = titles.map(item => createColumn(item, customRender))

        if(actionColumnFirst){
            return [actionsColumn, ...columns] as MUIDataTableColumnDef[]
        }
        return [...columns, actionsColumn] as MUIDataTableColumnDef[]
    }
    return []
}
