import { Alert, Box, CircularProgress, IconButton, alpha, styled } from '@mui/material';
import { LoadError } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import {RenderCurrentScaleProps, RenderZoomInProps, RenderZoomOutProps} from '@react-pdf-viewer/zoom';
import {RenderDownloadProps} from "@react-pdf-viewer/get-file";
import {RenderPrintProps} from "@react-pdf-viewer/print";
import {RenderEnterFullScreenProps} from '@react-pdf-viewer/full-screen';
import { ReactNode, useState } from 'react';

import fetcher from '../repository';

const headerHeight = 372

interface PdfViewerProps {
    url: string | null,
    children?: ReactNode
    onClose?: () => void,
}

export const PdfViewer = ({ url, children, onClose }: PdfViewerProps) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const [pdfLoaded, setPdfLoaded] = useState(false)

    const token = fetcher.getAccessToken()
    
    const headers = {"Authorization": `Bearer ${token}`}

    if(!!url && !!token){
        return (
            <>
                <>
                    
                        <StyledToolbarWrapper>
                            {children}

                            {pdfLoaded ? (
                                <Toolbar>
                                    {(props: ToolbarSlot) => {
                                        const {
                                            EnterFullScreen,
                                            CurrentScale,
                                            ZoomIn,
                                            ZoomOut,
                                            Download,
                                            Print
                                        } = props;
                                        return (
                                            <>
                                                <Box>
                                                    <ZoomOut>
                                                        {(props: RenderZoomOutProps) => (
                                                            <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                                <ZoomOutIcon/>
                                                            </IconButton>
                                                        )}
                                                    </ZoomOut>
                                                </Box>
                                                <Box>
                                                    <CurrentScale>
                                                        {(props: RenderCurrentScaleProps) => (
                                                            <span>{`${Math.round(props.scale * 100)}%`}</span>
                                                        )}
                                                    </CurrentScale>
                                                </Box>
                                                <Box>
                                                    <ZoomIn>
                                                        {(props: RenderZoomInProps) => (
                                                            <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                                <ZoomInIcon/>
                                                            </IconButton>
                                                        )}
                                                    </ZoomIn>
                                                </Box>
                                                <Box sx={{marginLeft: "auto"}}>
                                                    <Download>
                                                        {(props: RenderDownloadProps ) => (
                                                            <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                                <DownloadIcon/>
                                                            </IconButton>
                                                        )}
                                                    </Download>
                                                    <Print>
                                                        {(props: RenderPrintProps) => (
                                                            <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                                <PrintIcon/>
                                                            </IconButton>
                                                        )}
                                                    </Print>
                                                    <EnterFullScreen>
                                                        {(props: RenderEnterFullScreenProps) => (
                                                            <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                                <FullscreenIcon/>
                                                            </IconButton>
                                                        )}
                                                    </EnterFullScreen>
                                                </Box>
                                            </>
                                        );
                                    }}
                                </Toolbar>
                            ) : null}
                        </StyledToolbarWrapper>
                    {!!onClose ? (
                        <StyledCloseWrapper>
                            <IconButton style={{cursor: "pointer"}} onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </StyledCloseWrapper>
                    ) : null}
                </>

                <Viewer
                    fileUrl={url}
                    httpHeaders={headers}
                    plugins={[toolbarPluginInstance]}
                    renderError={renderError}
                    renderLoader={() => <CustomLoader />}
                    onDocumentLoad={() => setPdfLoaded(true)}      
                />
            </>
        )
    }

    return <></>
}

const renderError = (error: LoadError) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'The document is invalid or corrupted';
            break;
        case 'MissingPDFException':
            message = 'The document is missing';
            break;
        case 'UnexpectedResponseException':
            message = 'Unexpected server response';
            break;
        default:
            message = 'Cannot load the document';
            break;
    }

    return (
        <StyledErrorWrapper>
            <Alert severity='error' variant='filled' icon={false}>
                {message}
            </Alert>
        </StyledErrorWrapper>
    );
};

const StyledErrorWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${headerHeight}px)`,
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.error.light, 0.2),
    borderRadius: '0.25rem',
    width: '100%',
    alignItems: 'center'
}))

const CustomLoader = ()  => {
    return (
        <StyledLoaderWrapper>
            <CircularProgress />
        </StyledLoaderWrapper>
    )
}

const StyledLoaderWrapper = styled('div')(() => ({
    height: `calc(100vh - ${headerHeight}px)`,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    alignItems: 'center'
}))

const StyledCloseWrapper = styled('div')({
    position: 'absolute',
    right: '1rem',
    top: '1rem',
})

const StyledToolbarWrapper = styled('div')(({theme}) => ({
    alignItems: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    flex: '1 0',
    padding: '0 2rem 0 0',
    minHeight: theme.spacing(5),
    marginBottom: theme.spacing(2)
}))
