import { Box, Button, Theme, Typography } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import { useCallback, useState } from "react"

import Configuration from "./Configuration"
import getConfig, { AppConfig, isRuntimeConfigurable, setRuntimeConfig } from "../config"


interface LoginProps {
    onClick: () => void
}

export const Login = ({ onClick }: LoginProps) => {
    const [formState, setFormState] = useState(getConfig());

    const handleSubmit = useCallback(() => {
        setRuntimeConfig(formState!);
        onClick()
    }, [onClick, formState])

    const classes = useStyles()

    return (
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' height='100%'>
            <div className={classes.wrapper}>
                <div className={classes.logo}>
                    <img className={classes.image} src="/logo.png" alt="logo" />
                    <Typography variant='h3' component='h1'>ContentGrid App</Typography>
                </div>
                {isRuntimeConfigurable() ? <Configuration config={formState} onChange={setFormState} /> : null}
                <Button variant="contained" disableElevation onClick={handleSubmit} disabled={!isConfigurationValid(formState)}>Log in</Button>
            </div>
        </Box>
    )
}

function isConfigurationValid(config: AppConfig|null) {
    if(config === null) {
        return false;
    }
    if(!config.apiBaseUrl) {
        return false;
    }
    if(!config.oidc.authority) {
        return false;
    }
    if(!config.oidc.client_id) {
        return false;
    }
    return true;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            marginBottom: theme.spacing(2),
            width: theme.spacing(10)

        },
        logo: {
            position: 'absolute',
            textAlign: 'center',
            width: 'max-content',
            left: '50%',
            transform: 'translate(-50%, -190%)'
        },
        wrapper:{
            display: 'flex',
            flexDirection: 'column',
            width: theme.spacing(80),
            position: 'relative'
        }
    })
);
