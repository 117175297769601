import { useAuth } from "react-oidc-context";
import { styled } from "@mui/system";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Header } from "./components/Header";
import { FormPage } from "./components/FormPage";
import { Main } from "./components/Main";
import { ResultsPage } from "./components/ResultsPage";
import { LoadingPage } from "./components/LoadingPage";
import { CreateEntityInstance } from "./components/CreateEntityInstance";
import { EntityInstance } from "./components/EntityInstance/EntityInstance";
import { EditEntityInstance } from "./components/EditEntityInstance";
import { Login } from "./components/Login";
import { Worker } from "@react-pdf-viewer/core";
import { AllRelationsPage } from "./components/EntityInstance/AllRelationsPage";

const pdfWorkerUrl = new URL('pdfjs-dist/build/pdf.worker', import.meta.url)

export const App = () => {
    const auth = useAuth();

    switch (auth.activeNavigator) {
      case "signinSilent":
          return <div>Signing you in...</div>;
      case "signoutRedirect":
          return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <LoadingPage />
    }

    if (auth.error) {
        return <Login onClick={() => void auth.signinRedirect()} />
    }

    if (auth.isAuthenticated) {
        return (
            <BrowserRouter>
                <StyledWrapper>
                    <Worker workerUrl={pdfWorkerUrl.href}>
                    <Header onClick={() => void auth.signoutRedirect()}>{auth.user?.profile.preferred_username}</Header>

                    <Routes>
                        <Route path='/' element={<Main />}>
                            <Route path=':slug' element={<FormPage />}>
                                <Route path='search' element={<ResultsPage />} />
                            </Route>
                            <Route path=':slug/create' element={<CreateEntityInstance />} />
                            <Route path=':slug/details/:instance' element={<EntityInstance />} />
                            <Route path=':slug/details/:instance/:relation' element={<AllRelationsPage />} />
                            <Route path=':slug/edit/:instance' element={<EditEntityInstance />} />
                        </Route>
                    </Routes>
                    </Worker>
                </StyledWrapper>
            </BrowserRouter>
        );
    }

    return <Login onClick={() => void auth.signinRedirect()} />
}

const StyledWrapper = styled('div')({
    height: '100vh',
    overflow: 'hidden'
})
