import { PanTool } from "@mui/icons-material";
import { Alert, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, useState } from "react";
import cx from 'classnames'

import { FileData, UploadFileStatusType } from "../../repository/models";
import { UploadProgress } from "./UploadProgress";
import { FileCard } from "./FileCard";
import { formatFileSize } from "../helpers";

interface EditContentProps {
    value: string,
    data: FileData | null,
    status: UploadFileStatusType | null,
    onUpload: (file: File | null, url: string) => void
}

export const EditContent = ({data, value, status, onUpload}: EditContentProps) => {
    const classes = useStyles()
    const [drag, setDrag] = useState(false)

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if(!!event.target.files){
            const currentFile = event.target.files?.[0]

            if(!!currentFile){
                onUpload(currentFile, value)
            }
        }
    }

    const handleDelete = () => {
        setDrag(false)
        onUpload(null, value)
    }

    const labelClasses = cx(classes.label, drag ? classes.labelFocused : null)
    const iconClasses = cx(classes.dragIcon, drag ? classes.dragFocused : null)

    const size = formatFileSize(parseInt(data?.["content-length"] ?? '0'))

    return (
        <>
            {!!data ? (
                <>
                    <FileCard
                        name={data["content-name"]}
                        size={size}
                        type={data["content-type"]}
                        onClick={handleDelete}
                    />

                    {!!status ? <UploadProgress status={status} /> : null}
                </>
            ) : (
                <label className={labelClasses} htmlFor="upload-file">
                    <span className={classes.textWrapper}>
                        <Typography className={classes.text} component='span' variant='h5'>Drop here to upload</Typography>
                        <PanTool className={iconClasses} fontSize='large' />
                    </span>

                    <input
                        className={classes.input}
                        id="upload-file"
                        type="file"
                        name="file"
                        draggable
                        onChange={handleFileUpload}
                        onDragOver={() => setDrag(true)}
                        onDragLeave={() => setDrag(false)}
                    />
                    <Button size='small' variant="outlined">Upload</Button>
                </label>
            )}


            {!!status?.error ? <Alert severity='error'>Uploading was failed!</Alert> : null}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    textWrapper: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-33%, -50%)',
        right: 0,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        color: theme.palette.grey[700],
        opacity: 0.2,
    },
    dragIcon: {
        color: theme.palette.grey[700],
        opacity: 0.1,
        marginLeft: theme.spacing(2)
    },
    dragFocused: {
        color: theme.palette.primary.main
    },
    input: {
        zIndex: 1,
        position: 'absolute',
        width: 'min-content',
        height: '30px',
        opacity: 0,
        left: 0,
        top: 0,
        cursor: 'pointer',
        '& #file-upload-button': {
            cursor: 'pointer',
        }
    },
    label: {
        width: '100%',
        height: theme.spacing(16),
        display: 'block',
        position: 'relative',
        cursor: 'pointer',
        borderStyle: 'dashed',
        borderColor: theme.palette.divider,
        borderWidth: '1px',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        '& input': {
            width: '100%',
            height: '100%',
        }
    },
    labelFocused: {
        borderColor: theme.palette.primary.main
    }
}));
