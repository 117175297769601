import { Box } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";

import { TextInput } from "./TextInput";

export const DateGroup = ({ id, label, value, onChange }: WidgetProps) => {
    return (
        <Box sx={{ opacity: 0.6, pointerEvents: 'none', display: 'grid', gridTemplateColumns: '11rem 1fr', columnGap: '1rem', alignItems: 'center' }}>
            <label htmlFor={id}>{label}</label>

            <TextInput
                id={id}
                placeholder='mm/dd/yyyy'
                value={value || ''}
                maxLength={10}
                handleOnChange={onChange}
            />

            {/* {!!value && (
                <Box sx={{ position: 'absolute', right: '2rem', top: '0px' }}>
                    <ClearButton onClear={() => {}} />
                </Box>
            )} */}
        </Box>
    )
}

// interface DatePickerProps {
//     value: string,
//     onChange: (value: string) => void
// }

// const DatePicker = ({ value, onChange }: DatePickerProps) => {
//     return (
//         <StyledInput tabIndex={1} type='date' value={value} onChange={(e) => onChange(e.target.value)} />
//     )
// }

// const StyledInput = styled('input')({
//     position: 'absolute',
//     width: '1.5rem',
//     height: '1.5rem',
//     backgroundColor: 'transparent',
//     border: '0',
//     cursor: 'pointer',
//     right: '0.5rem',
//     '&:focus': {
//         border: 0,
//         outline: 0
//     }
// })
