import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UploadFileStatusType } from "../../repository/models";

interface UploadFileWithProgressProps {
    status: UploadFileStatusType
}

export const UploadProgress = ({status}: UploadFileWithProgressProps) => {
    const classes = useStyles()

    if(status.percentage === 100 && status.loaded){
        return <></>
    }

    if(status.percentage === 0 && !status.loaded){
        return <></>
    }

    const percents = status.percentage.toFixed(0) === '100' ? '99' : status.percentage.toFixed(0)
  
    return (
        <Box display='flex' alignItems='center' marginBottom={2} marginTop={2}>
            <div className={classes.percentage}>
                <span style={{width: percents+'%'}} className={classes.percentageValue} />
            </div>

            <Box display='flex' alignItems='center'>
                <Typography variant='body2'>{percents}%</Typography>
            </Box>
            
            {!!status.error ? (
                <Box marginTop={2}>
                    <Typography color='error'>File was not loaded!</Typography>
                </Box>
            ) : null}
        </Box>
    );
  };

const useStyles = makeStyles((theme) => ({
    percentage: {
        backgroundColor: 'transparent',
        border: '1px solid '+theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        height: theme.spacing(1),
        width: theme.spacing(16),
        position: 'relative',
        marginRight: theme.spacing(2),
        overflow: 'hidden'
    },
    percentageValue: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 0,
        backgroundColor: theme.palette.primary.main
    }
}));
