import { MUIDataTableMeta } from 'mui-datatables'
import { Typography } from '@mui/material';
import { Check, Remove } from '@mui/icons-material'

interface CustomCellProps {
    value: unknown,
    tableMeta: MUIDataTableMeta
}

export const CustomCell = (props: CustomCellProps) => {
    if(typeof props.value === 'boolean'){
        return props.value === true ? <Check /> : <Remove />
    }

    if(props.value === null){
        return <></>
    }

    return (
        <Typography component='span' textOverflow='ellipsis' variant='body2'>{String(props.value)}</Typography>
    );
}
