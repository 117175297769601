import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { IChangeEvent } from "@rjsf/core";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { RJSFSchema } from "@rjsf/utils";
import { HalObject } from "@contentgrid/hal";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { JsonForm } from "../ui/form/JsonForm";
import { EntityInstance, UploadFileStatusType } from "../repository/models";
import { RequestStateHandler } from "../ui/RequestStateHandler";
import { getContentLinks } from "../ui/form/helpers";
import api from "../store/api";
import { ServerErrorMessage } from "../ui/ServerErrorMessage";
import { upload } from "./EntityInstanceEditContent/upload";
import { EditContentField } from "./EntityInstanceEditContent/EditContentField";

export const EditEntityInstance = () => {
    const {slug, instance} = useParams()
    const { data, isLoading, error } = api.useGetEntityInstanceQuery(`${slug}/${instance}`, { skip: !slug || !instance })

    if(!!data){
        return (
            <PageBlockWrapper title='Edit'>
                {!!error || isLoading ? (
                    <Box marginBottom={2} marginTop={2}>
                        <RequestStateHandler
                            error={error}
                            isLoading={isLoading}
                        />
                    </Box>
                ) : !!data ? (
                    <EditContent
                        entity={data}
                        slug={slug!}
                        instance={instance!}
                    />
                ) : null}
            </PageBlockWrapper>
        )
    }

    return null
}

const EditContent = ({entity, slug, instance}: {entity: EntityInstance, slug: string, instance: string}) => {
    const navigate = useNavigate()
    const [values, setValues] = useState<IChangeEvent<any, RJSFSchema, any>>({formData: entity} as IChangeEvent<any, RJSFSchema, any>)
    const [editInstance, { error }] = api.useEditEntityInstanceMutation()
    const [files, setFiles] = useState<{file: File | null, url: string}[]>([])
    const [uploadStatus, setUploadStatus] = useState<UploadFileStatusType[]>([])
    const [deleteContent, { error: deleteError }] = api.useDeleteEntityInstanceContentMutation()
    const [loading, setLoading] = useState(false)

    const properties = entity._templates?.default?.properties

    const handleChange = (data: IChangeEvent) => {
        setValues(data)
    }

    const contentLinks = getContentLinks(new HalObject(entity))

    const onSubmit = async function () {
        const isValues = !!Object.values(values?.formData).filter(item => !!item).length
        if(isValues && !!entity){
            setLoading(true)

            await editInstance({entity: entity, value: values?.formData}).unwrap()

            for(let item of files){
                if(!item.file){
                    await deleteContent({url: item.url}).unwrap()
                }

                if(!!item.file){
                    await upload(item.url, item.file!, (percent, status) => {
                        setUploadStatus([...uploadStatus, {
                            percentage: percent,
                            url: item.url,
                            loaded: !!status?.loaded,
                            error: !!status?.error
                        }])
                    })
                }
            }

            if(!error || !deleteError || !uploadStatus.find(item => !!item.error)){
                navigate(`../${slug}/details/${instance}`)
            }

            setLoading(false)
        }
    }

    const handleUpload = (file: File | null, url: string) => {
        const list = files?.filter(item => item.url !== url)

        setFiles([...list, {file, url}])
    }

    return (
        <>
            {!!error ? <ServerErrorMessage error={error} /> : null}
            {!!properties ? (
                <JsonForm
                    properties={properties}
                    formData={values?.formData}
                    onChange={handleChange}
                >
                    {contentLinks.map((link) => {
                        const status = uploadStatus.find(item => item.url === link.href)
                        const file = files.find(item => item.url === link.href)?.file

                        return (
                            <EditContentField
                                key={link.href}
                                file={file}
                                link={link}
                                status={status || null}
                                onUpload={handleUpload}
                            />
                        )
                    })}

                    <Box display='flex' justifyContent='flex-end' marginTop={2} gap={2}>
                        <Button color='secondary' onClick={() => navigate(`/${slug}/search`)}>Cancel</Button>
                        <LoadingButton loading={loading} id='submit-button' variant='contained' onClick={onSubmit}>Save</LoadingButton>
                    </Box>
                </JsonForm>
            ) : null}
        </>
    )
}

