import { styled } from "@mui/material"

import { MainLogo } from "../ui/MainLogo"
import { HeaderMenu } from "../ui/HeaderMenu"

interface HeaderProps {
    children: React.ReactNode
    onClick: () => void
}

export const Header = ({ children, onClick }: HeaderProps) => {
    return (
        <StyledHeader>
            <MainLogo />

            <HeaderMenu title={children} onClick={onClick} />
        </StyledHeader>
    )
}

const StyledHeader = styled('header')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: theme.spacing(10),
    padding: `0 ${theme.spacing(4)}`,
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'url(/bg.png)',
    backgroundSize: 'cover',
}))
