import { Table, TableBody, TableCell, TableRow, Typography, Alert, Link as MuiLink, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HalSlice, Link } from "@contentgrid/hal";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import { Link as NavLink } from "react-router-dom";

import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { getColumns, isIsoDate } from "../../ui/table/Table/helpers";
import { convertFromIso, getPropertyList } from "../../ui/form/helpers";
import { ActionsCell } from "./ActionsCell";
import { TableBody as MUITableBody } from "../../ui/table/Table/components/TableBody";
import { createActionsColumn } from "../helpers";


const resultLength = 5

export const RelationDetails = ({link, data, error, isLoading}: {link: Link, data: any, error: FetchBaseQueryError | SerializedError | undefined, isLoading: boolean}) => {
    const isMultipleRelation = !!data && '_embedded' in data
    const classes = useStyles()

    if(!!error && 'status' in error && error?.status === 404){
        return <Alert severity='info'>Instance does not have related entity</Alert>
    }

    if(!data && (isLoading || !!error)){
        <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if(isMultipleRelation){
        const halSlice = new HalSlice(data)

        if(!halSlice.items.length){
            return <Alert severity='info'>Instance does not have related entity</Alert>
        }

        const columns = getColumns(
            halSlice,
            createActionsColumn((_value: unknown, tableMeta: MUIDataTableMeta) => (
                <ActionsCell
                    tableMeta={tableMeta}
                    halSlice={halSlice}
                    link={link} />
            )),
            null,
            true
        )

        const instances = halSlice.items.length > resultLength ? halSlice.items.slice(0, resultLength) : halSlice.items

        return (
            <>
                <div className={classes.wrapper}>
                    <MUITableBody
                        withTitle={false}
                        instances={instances}
                        columns={columns}
                        elements={(halSlice?.data as any)?.page?.totalElements}
                        settings={tableOptions}
                    />
                </div>

                {halSlice.items.length > resultLength ? (
                    <Box display='flex'justifyContent='center' marginTop={2} marginBottom={1}>
                        <MuiLink component={NavLink} to={'./'+link.name}>Show all</MuiLink>
                    </Box>
                ) : null}
            </>
        )
    }

    if(!!data){
        const results = getPropertyList(data, [])
        return (
            <Table size='small' sx={{marginBottom: '2rem'}}>
                <TableBody>
                    {results.map(item => (
                        <TableRow key={item[0]}>
                            <TableCell size='small' sx={{ maxWidth: '250px', verticalAlign: 'top' }}>
                                <Typography variant='body2' color='textSecondary' component='span'>{item[0]}:</Typography>
                            </TableCell>

                            <TableCell>
                                <Typography variant='body2' component='span'><UpdatedValue value={item[1]} /></Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return <></>
}

const tableOptions: MUIDataTableOptions = {
    rowHover: false,
    download: false,
    print: false,
    search: false,
  }
  

const useStyles = makeStyles((theme) => ({
    wrapper: {
        '& .MuiToolbar-root': {
            minHeight: 'auto',
            //width: '40px',
            padding: 0
        },
        '& .MUIDataTableToolbar-left': {
            display: 'none',
            color: 'red'
        },
        '& .MuiTableCell-root:last-child': {
            width: 'auto',
        },
        '& .MuiTableCell-root:first-child': {
            width: '48px',
            padding: theme.spacing(1)
        }
    }
}));


const UpdatedValue = ({value}: {value: null | string | string[]}) => {
    if(value === null){
        return <></>
    }

    if(Array.isArray(value)){
        const content = value.map(item => isIsoDate(item) ? convertFromIso(item) : item)
        return (
            <Typography color='textPrimary' component='span'>
                <>{content.join(', ')}</>
            </Typography>
        )
    }

    const content = isIsoDate(value) ? convertFromIso(value) : value
    return (
        <Typography color='textPrimary' component='span'>
            <>{[content]}</>
        </Typography>
    )
}
