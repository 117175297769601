import { MUIDataTableMeta } from 'mui-datatables'
import { Box, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HalSlice, Link } from '@contentgrid/hal';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { DeleteForever, Toc } from '@mui/icons-material'
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { RootState } from '../../store/store';
import { selectProfileData } from '../../store/profileDataSlice';
import { getDetailsUrlPath } from '../helpers';
import { UnlinkRelationDialog } from './UnlinkRelationDialog';

interface ActionsCellProps {
    tableMeta: MUIDataTableMeta,
    halSlice: HalSlice<unknown>,
    link: Link
}

export const ActionsCell = ({tableMeta, halSlice, link}: ActionsCellProps) => {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const entityList = useTypedSelector(selectProfileData)
    const id = !!tableMeta.rowData[0] ? tableMeta.rowData[0] : tableMeta.rowData[1]

    const navigatePath = getDetailsUrlPath(
        id,
        halSlice,
        entityList!
    )

    const [opened, setOpened] = useState(false)
    const classes = useStyles();

    return (
        <Box display='flex' alignItems='center'>
            <Tooltip arrow placement='top' title='Details'>
                <NavLink className={classes.wrapper} to={navigatePath!}><Toc color='inherit' /></NavLink>
            </Tooltip>

            <Tooltip arrow placement='top' title='Unlink'>
                <span className={classes.wrapper} onClick={(e) => setOpened(true)}>
                    <DeleteForever color='inherit' fontSize='small' />
                </span>
            </Tooltip>

            <UnlinkRelationDialog
                link={link}
                id={id}
                opened={opened}
                onClose={() => setOpened(false)}
            />
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: 'center',
        width: '100%',
        display: 'inline-flex',
        color: theme.palette.grey[600],
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}))
