import { Box, Button } from "@mui/material";

interface PageNavigationProps {
    nextLink?: string,
    prevLink?: string,
    onNext: () => void,
    onPrev: () => void
}

export const PageNavigation = ({ nextLink, prevLink, onNext, onPrev }: PageNavigationProps) => {
    const handleNext = () => {
        if(!!nextLink){
            onNext()
        }
    }

    const handlePrev = () => {
        if(!!prevLink){
            onPrev()
        }
    }

    return (
        <Box display='flex'>
            <Box marginRight={2}>
                <Button disabled={!prevLink} variant='contained' type="submit" onClick={handlePrev}>Prev</Button>
            </Box>

            <Button disabled={!nextLink} variant='contained' type="submit" onClick={handleNext}>Next</Button>
        </Box>
    )
}
