import { parse } from 'content-disposition-attachment';

export function getFilenameFromContentDisposition(value: string) {
  const disposition = parse(value)

  if('filename' in disposition){
    return disposition.filename ? disposition.filename : null
  }

  return null;
}

export const convertArrayToUriList = (array: string[]): string => {
  return array.join('\n')
}
