import { Alert, Box, Button, ListItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "@contentgrid/hal";

import { PdfViewer } from "./PdfViewer";
import api from "../store/api";
import { RequestStateHandler } from "./RequestStateHandler";
import { FileData } from "../repository/models";

interface FileSelectProps {
    links: Link[],
    onClose?: () => void
}

export const FileSelect = ({links, onClose}: FileSelectProps) => {
    const classes = useStyles()
    const [url, setUrl] = useState<string>(links[0].href)

    const handleClose = () => {
        if(!!onClose){
            setUrl('')
            onClose()
        }
    }

    useEffect(() => {
        setUrl(links[0].href)
    },[links])

    return (
        <div className={classes.wrapper}>
            <ContentSelectWrapper
                select={(
                    <>
                        {links.length > 1 ? (
                            <Select
                                className={classes.select}
                                value={url}
                                defaultValue={url}
                                onChange={(e) => {
                                    setUrl(e.target.value as string)
                                }}
                            >
                                {links.map((link) => (
                                    <ListItem component={Button} className={classes.option} key={link.href} value={link.href}>{link.name}</ListItem>
                                ))}
                            </Select>
                        ) : links.length === 1 ? (
                            <Typography className={classes.text}>{links[0]?.name}</Typography>
                        ) : null}
                    </>
                )}
                url={url}
                onClose={handleClose}
            />
        </div>
    )
}

const ContentSelectWrapper = ({url, select, onClose}: {url: string, select: ReactNode, onClose: () => void}) => {
    const { data, isLoading, error } = api.useGetEntityInstanceContentQuery(url)

    if(!!error && 'status' in error && error?.status === 404){
        return (
            <>
                {select}

                <Box marginTop={2}>
                    <Alert severity='info'>No file present.</Alert>
                </Box>
            </>
        )
    }

    if(isLoading || !!error){
        return (
            <>
                {select}

                <RequestStateHandler isLoading={isLoading} error={error} />
            </>
        )
    }
        
    if(!!data){
        return (
            <ContentSelect select={select} data={data} url={url} onClose={onClose} />
        )
    }

    return <></>
}

const ContentSelect = ({data, url, select, onClose}: {data: FileData, url: string, select: ReactNode, onClose: () => void}) => {
    if(data["content-type"] === 'application/pdf'){
        return <PdfViewer url={url} children={select} onClose={onClose} />
    }

    return (
        <>
            {select}
            <Box marginTop={2}>
                <Typography gutterBottom>Preview is not available.</Typography>
                <Button href={url} variant='outlined'>Download</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    select: {
        maxWidth: '800px',
        width: 'max-content'
    },
    option: {
        cursor: 'pointer',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize
    },
    text: {
        border: '1px solid '+theme.palette.divider,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        width: 'max-content'
    },
    wrapper:{
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        padding: theme.spacing(2)
    }
}));
