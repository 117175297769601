import { HalObject, HalSlice } from "@contentgrid/hal"
import { ianaRelations } from "@contentgrid/hal/rels"
import { MUIDataTableMeta } from "mui-datatables"

export function formatFileSize(bytes: number) {
  if(bytes === 0) return '0 Bytes';
  const  k = 1000,
  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const getDetailsUrlPath = (value: unknown, halSlice: HalSlice<unknown>, entityList: {[k: string]: string}) => {
  const instance =  getInstance(halSlice, value)

  const entityName = getEntityName(halSlice, entityList)

  if(!!instance && 'id' in instance?.data! && !!entityName){
    return '/'+entityName+'/details/'+instance?.data.id
  }

  return null
}

const getEntityName = (halSlice: HalSlice<unknown>, entityList: {[k: string]: string}) => {
  const profileUrl = halSlice.links.findLink(ianaRelations.profile)?.href

  const name = Object.keys(entityList).find(key => entityList[key] === profileUrl)
 
  return !!name ? name : null
}

const getInstance = (halSlice: HalSlice<unknown>, value: unknown) => {
  if(!!halSlice.items.length){
    return halSlice.items.find(item => 'id' in item.data && item.data.id === value)
  }

  return new HalObject(halSlice.data)
}

export const createActionsColumn = (render: (_value: unknown, tableMeta: MUIDataTableMeta) => JSX.Element) => {
  return {
      name: '',
      label: 'Actions',
      options: { customBodyRender: render }
  }
}
