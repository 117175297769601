import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { ProgressIndicator } from './ProgressIndicator'
import { ServerErrorMessage } from './ServerErrorMessage'

interface RequestStateHandlerProps {
  isLoading?: boolean,
  error?: FetchBaseQueryError | SerializedError,
  loadingSize?: number,
  errorClasses?: string,
}

export const RequestStateHandler = ({ isLoading, loadingSize, error, errorClasses }: RequestStateHandlerProps) => {
  if(isLoading){
    return <ProgressIndicator loadingSize={loadingSize} />
  }

  if(!error){
    return null
  }

  return (
    <ServerErrorMessage classes={errorClasses} error={error} />
  )
}