import { ReactNode } from "react";
import cx from 'classnames'
import { Box, Grid, styled } from "@mui/material";

import { BlockTitle } from "../ui/text/BlockTitle"

interface PageBlockWrapperProps {
  title?: string | ReactNode,
  className?: string,
  isSidebar?: boolean,
  withoutSpacings?: boolean,
  children: ReactNode,
  header?: ReactNode,
}

export const PageBlockWrapper = ({ title, className, isSidebar, withoutSpacings, children, header }: PageBlockWrapperProps) => {
  const classes = cx(className, {
    'is-sidebar': isSidebar,
    'is-spacings-disabled': withoutSpacings
  })

    return (
        <StyledWrapper className={classes} container direction='column'>
          {!!header && (
            <Box maxWidth='100%' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
              <BlockTitle mb={0}>{title}</BlockTitle>

              {header}
            </Box>
          )}
          
          {!header && !!title && (
            <BlockTitle>{title}</BlockTitle>    
          )}

            {children}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Grid)(({theme}) => ({
    padding: '1rem 2rem',
    '&.is-sidebar': {
      borderRight: '1px solid '+theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      paddingBottom: theme.spacing(6),
    },
    '&.is-spacings-disabled': {
      padding: 0
    }
}))
