import { useEffect, useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import { alpha, styled } from '@mui/material';
import { HalObject } from '@contentgrid/hal';

import { tableOptions } from '../variables';
import { updateRowDates } from '../helpers';

interface TableBodyProps {
    instances: readonly HalObject<unknown>[],
    elements: number,
    columns: MUIDataTableColumnDef[],
    settings?: MUIDataTableOptions,
    withTitle?: boolean,
    onSelectInstance?: (val: HalObject<unknown> | null) => void
}
export const TableBody = ({ instances, elements, columns, settings, withTitle = true, onSelectInstance }: TableBodyProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
    const details = useMemo(() => {
        return instances.map(detail => {
            const obj: {[k: string]: any} = {}
            Object.entries(detail.data).forEach(entry => {
                if(typeof entry[1] !== 'object' || entry[1] === null){
                    obj[entry[0]] = entry[1]
                }
            })
            return obj
        })
    },[instances])

    useEffect(() => {
        if(!!onSelectInstance){
            if(selectedIndex !== null){
                const selectedRow = instances[selectedIndex]

                onSelectInstance(selectedRow)
                return
            }
            onSelectInstance(null)
        }
    }, [ selectedIndex, onSelectInstance, instances ])


    const handleRowClick = (_rowData: string[], rowMeta: { dataIndex: number; rowIndex: number; }) => {
        const isNotSelectedText = !window?.getSelection()?.toString()
        
        if(isNotSelectedText){
            const index = rowMeta.rowIndex
            if(index !== selectedIndex){
                setSelectedIndex(index)
                return
            }
            setSelectedIndex(null)
        }
    }

    const updatedOptions = {
        ...tableOptions,
        ...settings,
        onRowClick: handleRowClick
    }

    const currentTitle = withTitle && !!elements ? 'Results: '+elements : ''

    return (
        <StyledWrapper id={selectedIndex?.toString()}>
            <MUIDataTable
                title={currentTitle}
                data={updateRowDates(details)}
                columns={columns}
                options={updatedOptions}
            />
        </StyledWrapper>
    ) 
}

const StyledWrapper = styled('div')(({ theme, id }) => ({
    height: 'max-content',
    width: '100%',
    ['& tr[data-testid="MUIDataTableBodyRow-'+id+'"]']: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1)+'!important'
    },
    '& .MuiTableCell-root:last-child': {
        width: '48px',
        padding: theme.spacing(2)
    }
}))

