import { useNavigate, useParams } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReactNode, useState } from "react";
import { DeleteForever } from "@mui/icons-material";

import api from "../store/api";
import { ServerErrorMessage } from "../ui/ServerErrorMessage";
import { RequestStateHandler } from "../ui/RequestStateHandler";

export const DeleteEntityInstance = ({icon, id}: {icon?: ReactNode, id: string}) => {
    const { slug, instance } = useParams()
    const instanceId = !!instance ? instance : id
    const navigate = useNavigate()
    const { data, isLoading, error } = api.useGetEntityInstanceQuery(`${slug}/${instanceId}`, { skip: !slug })
  
    const [opened, setOpened] = useState(false)
    const [deleteInstance, { isLoading: deleteLoading, error: deleteError }] = api.useDeleteEntityInstanceMutation()

    const handleDelete = async () => {
        if(!!slug && !!instanceId && !!data){
            await deleteInstance({entity: data}).unwrap()
            setOpened(false)
            navigate(`../../${slug}/search`)
        }
    }

    return (
        <>
            {!!icon ? (
                <Tooltip arrow placement='top' title='Delete'>
                    <span onClick={(e) => setOpened(true)}>{icon}</span>
                </Tooltip>
            ) : (
                <Button variant='outlined' color='error' onClick={() => setOpened(true)} startIcon={<DeleteForever />}>
                    Delete
                </Button>
            )}

            <Dialog open={opened} onClose={() => setOpened(false)}>
                {isLoading || !!error ? (
                    <RequestStateHandler isLoading={isLoading} error={error} />
                ) : (
                    <>
                        <DialogTitle>Delete {slug} instance</DialogTitle>
                        <DialogContent>
                            <Typography>Are you sure you want to delete this instance of <i>{slug}</i>?</Typography>
                            {!!deleteError ? <ServerErrorMessage error={deleteError} /> : null}
                        </DialogContent>
                        <DialogActions>
                            <Button color='secondary' onClick={() => setOpened(false)}>Cancel</Button>
                            <LoadingButton color='error' loading={deleteLoading} variant="contained" onClick={handleDelete}>Delete</LoadingButton>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    )
}
