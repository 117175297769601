import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link } from "@contentgrid/hal";

import { ServerErrorMessage } from "../../ui/ServerErrorMessage";
import api from "../../store/api";

export const UnlinkRelationDialog = ({opened, link, id, onClose}: {link: Link, opened: boolean, id?: string, onClose: () => void}) => {
    const { slug, instance, relation } = useParams()
    const navigate = useNavigate()

    const [deleteInstance, { isLoading: deleteLoading, error: deleteError }] = api.useDeleteEntityRelationMutation()

    const handleDelete = async () => {
        const url = !!id ? link.href+'/'+id : link.href
        await deleteInstance({value: url}).unwrap()
        onClose()
        if(!relation){
            navigate(`../${slug}/details/${instance}`)
        }
    }

    return (
        <Dialog open={opened} onClose={onClose}>
            <DialogTitle>Unlink relation</DialogTitle>
            <DialogContent>
                {!!deleteError ? <Box marginTop={2}><ServerErrorMessage error={deleteError} /></Box> : null}
                <Typography>Are you sure you want to unlink this relation from <i>{slug}</i>?</Typography>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={onClose}>Cancel</Button>
                <LoadingButton color='error' loading={deleteLoading} variant="contained" onClick={handleDelete}>Unlink</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
