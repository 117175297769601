import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from "./store";

export const selectSearchParams = (state: RootState) => state.searchParams;

type SearchParamsStateType = {[k: string]: string} | null; 
const initialState = null as SearchParamsStateType

export const searchParamsSlice = createSlice ({
  name: "searchParams",
  initialState,
  reducers: {
    updateSearchParams: (
        state: SearchParamsStateType,
        { payload }: PayloadAction<SearchParamsStateType>
      ) => (
        state = payload
      )
  }
})

export const { updateSearchParams } = searchParamsSlice.actions;

export const searchParamsReducer = searchParamsSlice.reducer;
