import { useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { Box, styled } from '@mui/material';
import { HalObject, HalSlice } from '@contentgrid/hal';
import { MUIDataTableMeta } from 'mui-datatables';

import { calculateDraggablePane, getColumns, getTableColumnSizes } from './helpers';
import { TableBody } from './components/TableBody';
import { Viewer } from './components/Viewer';
import { ActionsCell } from './components/ActionsCell';
import { CustomCell } from './components/CustomCell';

interface TableProps {
    data: readonly HalObject<unknown>[],
    elements: number,
    halSlice: HalSlice<unknown>
}

export const Table = ({ data, elements, halSlice }: TableProps) => {
    const [ selectedInstance, setSelectedInstance ] = useState<HalObject<unknown> | null>(null)
    const [ dividerLocation, setDividerLocation ] = useState(0)

    const handleDrag = (data: DraggableData) => {
        setDividerLocation((window.outerWidth/2) + data.x)
    }

    const draggableData = calculateDraggablePane()

    const handleClose = () => {
        setSelectedInstance(null)
        setDividerLocation(0)
    }

    const leftColumnRef = useRef<HTMLDivElement>(null)
    const rightColumnRef = useRef<HTMLDivElement>(null)

    getTableColumnSizes(
        rightColumnRef,
        leftColumnRef,
        !!selectedInstance,
        dividerLocation
    )

    const columns = getColumns(
        halSlice,
        actionsColumn,
        (value: unknown, tableMeta: MUIDataTableMeta) => <CustomCell value={value} tableMeta={tableMeta} />
    )

    return (
        <Box display='flex' width='100%'>
            <Box ref={leftColumnRef} width='100%'>
                <TableBody
                    instances={data}
                    columns={columns}
                    elements={elements}
                    onSelectInstance={setSelectedInstance}
                />
            </Box>

            <Draggable
                axis="x"
                bounds={draggableData}
                onDrag={(_e, value) => handleDrag(value)}
            >
                {!!selectedInstance ? (<StyledDivider style={{left: dividerLocation}} />) : (<></>)}
            </Draggable>

            <Viewer
                ref={rightColumnRef}
                instance={selectedInstance}
                onClose={handleClose}
            />
        </Box>
    )
}

const StyledDivider = styled('span')(({theme}) => ({
    height: '100%',
    minWidth: theme.spacing(6),
    transform: 'none !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'col-resize',
    marginTop: theme.spacing(3),
    '&::before': {
        content: '""',
        display: 'block',
        width: '12px',
        height: '20px',
        borderLeft: '3px solid '+theme.palette.primary.main,
        borderRight: '3px solid '+theme.palette.primary.main,
        transition: 'width 150ms ease,height 150ms ease,transform 150ms ease,background-color 150ms ease,-webkit-transform 150ms ease'
    },
    '&:hover': {
        '&::before': {
            borderColor: theme.palette.primary.dark,
            transition: 'transform 200ms eae-out',
            transform: 'scale(1.2)',
        }
    }
}))

const actionsColumn =  {
    name: '',
    label: 'Actions',
    options: {
        customBodyRender: (_value: unknown, tableMeta: MUIDataTableMeta) => <ActionsCell tableMeta={tableMeta} />
    }
}
