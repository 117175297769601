import { RJSFSchema, RJSFValidationError } from "@rjsf/utils"
import { HalFormsPropertyShape } from "@contentgrid/hal-forms/shape"
import { HalObject } from "@contentgrid/hal"

import cgRelations from "../../repository/relations";
import { EntityInstance } from "../../repository/models"

export const transformErrors = (errors: RJSFValidationError[]) => {
  return errors.map(error => {
      if (error.params?.format === "date") {
          error.message = "Invalid date format"
      }
      return error
  })
}

export const convertFromIso = (value: string) => {
    const event = new Date(Date.parse(value))

    return event.toLocaleDateString('en-GB')
}

export const getContentLinks = (hal: HalObject<unknown>) => {
    return hal.links.findLinks(cgRelations.content);
}

export const getPropertyObject = (properties: readonly HalFormsPropertyShape[]) => {
    const obj: RJSFSchema = {}
    for (let i = 0; i < properties?.length; i++) {
        const type = !properties[i].type || properties[i].type === 'text' ? 'string' : properties[i].type

        if(properties[i].type !== 'url'){//remove relation field
            const title = properties[i].name.replace('_',' ').replace('.',' ')
            obj[properties[i].name] = {
                title: title,
                type: type
            }
        }
    }

    return { properties: obj }
}

export const getPropertyList = (data: EntityInstance, contentNames: (string | undefined)[]) => {
    return (Object.entries(data)).filter(([name, value]) => {
        const readableType = (typeof value !== 'object' || value === null);
        return readableType && !contentNames.includes(name)
    })
}

export const getQueriesWithValue = (list: Iterable<[string, unknown]>) => {
    const obj: {[k: string]: string} = {};
    for (let [key, value] of list) {
        if (value !== '') {
            obj[key] = value+'';
        }
    }

    return obj
}
